import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class DailyUIRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-3" key={post.id}>
              <Link to={post.fields.slug}>
                <article
                  className={`dailyui-list-item tile is-child box notification ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
                  style={{padding: 0}}
                >
                  {post.frontmatter.featuredpost ? (<span className="ribbon"><span className="ribbon-content">NEW!</span></span>) : null}
                  <header>
                    <p>{post.frontmatter.title}</p>
                    {post.frontmatter.featuredimage ? (
                      <div className="featured-thumbnail">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${
                              post.title
                            }`,
                          }}
                        />
                      </div>
                    ) : <div style={{
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          height: `130px`,
                          color: `#ffffff`,
                          fontSize: `2rem`,
                          fontWeight: `bold`,}}>
                            <span>{post.frontmatter.title}{post.frontmatter.subheading}</span>
                        </div>}
                  </header>
                  <div className="post-meta" style={{padding: `1rem`}}>
                    <span className="title is-size-5">{post.frontmatter.subheading}</span>
                    <span className="is-size-6 is-block">
                      {post.frontmatter.date}
                    </span>
                    {post.frontmatter.tags && post.frontmatter.tags.length ? (
                      <ul className="taglist">
                        {post.frontmatter.tags.map(tag => (
                          <li key={tag + `tag`}>
                            <span>{tag}</span>
                          </li>
                        ))}
                      </ul>
                  ) : null}
                  </div>
              </article>
              </Link>
            </div>
          ))}
      </div>
    )
  }
}

DailyUIRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query DailyUIRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___title] }
          filter: { frontmatter: { templateKey: { eq: "dailyui-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                subheading
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                tags
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <DailyUIRoll data={data} count={count} />}
  />
)
