import React from 'react'
import Layout from '../../../components/Layout'
// import DailyUIRoll from '../src/components/DailyUIRoll'
import DailyUIRoll from '../../../components/DailyUIRoll'

const DailyUIProjectPage = () => (
  <Layout>
    <div
      className="full-width-image-container margin-top-0"
      style={{
          backgroundImage: `linear-gradient(-90deg, #34e694, #6e8fec)`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
      }}
    >
      <h1
        className="has-text-weight-bold is-size-1"
        style={{
          color: 'white',
          padding: '1rem',
        }}
      >
        DailyUI 100 challenge
      </h1>
    </div>
    <section className="section">
      <div className="container">
        <div className="content">
          <div className="tile">
            <h1>About challenge チャレンジにあたって</h1>
          </div>
          <p>平日の作業時間は移動時間のみしか取れそうもないので、まずは100までやりきるためにハードルを低く設定することにする。</p>
          <ul>
            <li>手書きのワイヤーも可とする</li>
            <li>時間とskech を手に入れたらワイヤーからブラッシュアップする</li>
          </ul>
          <p>ここまで低く設定してなんとか続けて見ようかと思う。手書きのワイヤー状態でアップしてる人なんてザッと見たところ居なさそうだけど、仕方ない。逆にここまで低く設定したら他の人がこれよりマシだと思ってくれたらするんだろうか…</p>
          <p>スタート日は、2018/10/17。いつ終わるのか…</p>
          <DailyUIRoll />
        </div>
      </div>
    </section>
  </Layout>
)

export default DailyUIProjectPage